import React from "react";
import "./HomePageServices.css";
import logo from "../../../Images/logo3.jpeg";
import service1 from "../../../Images/services-img/Steeldetailing.png";
import service2 from "../../../Images/services-img/Precastdetailing.png";
import service3 from "../../../Images/services-img/Detailingautomation.png";
import service4 from "../../../Images/services-img/estimatemodelling.jpg";
import { Link } from "react-router-dom";
const HomePageServices = () => {
  return (
    <div className="home-page-service">
      <h1 className="Home-page-service-h1">Our Services</h1>
      <div className="home-page-service-container-1">
        <div>
          <Link
            to="/services"
            onClick={() => {
              window.scroll(0, 0);
            }}
          >
            <div className="fplogo">
              <img src={logo} alt="fp1" />
              <h2>
                Structural steel <br /> Detailing
              </h2>
            </div>
            <div
              className="home-page-service-img-container-1"
              data-aos="fade-up"
              data-aos-duration="2000"
              data-aos-anchor-placement="top-bottom"
            >
              <img className="home-page-service-img1" src={service1} alt="" />
            </div>
          </Link>
        </div>
        <div>
          <Link
            to="/services"
            onClick={() => {
              window.scroll(0, 0);
            }}
          >
            <div className="fplogo">
              <img src={logo} alt="fp1" />
              <h2>
                Precast Concrete <br />
                Detailing
              </h2>
            </div>
            <div
              className="home-page-service-img-container-1"
              data-aos="fade-up"
              data-aos-duration="2000"
              data-aos-anchor-placement="top-bottom"
            >
              <img className="home-page-service-img1" src={service2} alt="" />
            </div>
          </Link>
        </div>
        <div>
          <Link
            to="/services"
            onClick={() => {
              window.scroll(0, 0);
            }}
          >
            <div className="fplogo">
              <img src={logo} alt="fp1" />
              <h2>
                Tekla <br /> Automation
              </h2>
            </div>
            <div
              className="home-page-service-img-container-1"
              data-aos="fade-up"
              data-aos-duration="2000"
              data-aos-anchor-placement="top-bottom"
            >
              <img className="home-page-service-img1" src={service3} alt="" />
            </div>
          </Link>
        </div>
        <div>
          <Link
            to="/services"
            onClick={() => {
              window.scroll(0, 0);
            }}
          >
            <div className="fplogo">
              <img src={logo} alt="fp1" />
              <h2>
                Estimation <br /> Modeling
              </h2>
            </div>
            <div
              className="home-page-service-img-container-1"
              data-aos="fade-up"
              data-aos-duration="2000"
              data-aos-anchor-placement="top-bottom"
            >
              <img className="home-page-service-img1" src={service4} alt="" />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HomePageServices;
