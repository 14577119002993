import React, { useState } from "react";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { ImLocation } from "react-icons/im";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { RiArrowRightSLine } from "react-icons/ri";
import { BsWhatsapp } from "react-icons/bs";
import "./Contact.css";

const SuccessResult = () => {
  return (
    <p className="contact-success">
      Your message has been successfully sent.We will contact you soon.
    </p>
  );
};
const FailureResult = () => {
  return (
    <p className="contact-failure">Error submitting form . Please check.</p>
  );
};
const Contact = () => {
  const [successResult, showSuccessResult] = useState(false);
  const [failureResult, showFailureResult] = useState(false);

  // const form = useRef();

  // const sendEmail = (e) => {
  //     e.preventDefault();

  //     emailjs.sendForm('service_jluk36o', 'template_rikd8yg', form.current, 'Y0gNSS5fKvVQ1Li5Ol')
  //       .then((result) => {
  //           console.log(result.text);
  //       }, (error) => {
  //           console.log(error.text);
  //       });
  //       e.target.reset()
  //       showResult(true)

  //   };
  const [formState, setFormState] = useState({});
  const changeHandler = (event) => {
    setFormState({ ...formState, [event.target.name]: event.target.value });
  };
  const submitHandler = (event) => {
    event.preventDefault();
    const config = {
      Username: "shanjidasultana2000@gmail.com",
      Host: "smtp.elasticemail.com",
      Password: "9667B6FD0304571CA4493CD8923FD777CD8A",
      SecureToken: "2e3d382f-643b-43c4-80af-a16e86e7b90a",
      Port: "2525",
      From: "shanjidasultana2000@gmail.com",
      To: "shanjidasultana2000@gmail.com",
      //  Reply-to: formState.email,
      Subject: "This is the subject",
      Body:
        formState.name +
        "  connected to you over email ," +
        "<br/> Email :" +
        formState.email +
        ", <br/> Phone Number :" +
        formState.phone +
        "<br/> Message :" +
        formState.message,
    };
    if (window.Email) {
      window.Email.send(config).then((message) => {
        if (message == "OK") {
          event.target.reset();
          showSuccessResult(true);
          setTimeout(() => {
            showSuccessResult(false);
          }, 5000);
        } else {
          event.target.reset();
          showFailureResult(true);
          setTimeout(() => {
            showFailureResult(false);
          }, 5000);
        }
      });
    }
  };

  return (
    <div id="contact">
      <div className="Contact">
        <div className="Contact-part1">
          <li>
            <h4>
              Thank you for taking the time to view our services.
              <br />
              We provide quality detailing services for both steel and precast
              projects.
              <br />
              For further inquiries, please contact us using the information
              below.
            </h4>
          </li>

          <li>
            <BsFillTelephoneFill />
            <p>
              <a href="tel:0061469743576">+ 61 (0)469 743 576</a>
            </p>
          </li>
          <li>
            <MdEmail />
            <p>
              <a href="mailto: alam.dptek@outlook.com">
                alam.dptek@outlook.com
              </a>
            </p>
          </li>
          <li>
            <ImLocation />
            <p>22 Cisticola St. Hinchinbrook, NSW 2168</p>
          </li>
          <li>
            <RiArrowRightSLine />
            <p>ABN 57869719664</p>
          </li>
          <div className="contact-social-media">
            <a href="https://twitter.com/DptekC99319" target="_blank" rel="noopener noreferrer">
              <FaTwitter />
            </a>
            <a href="https://www.instagram.com/dptekbc/" target="_blank" rel="noopener noreferrer">
              <AiFillInstagram />
            </a>
            <a href="https://www.facebook.com/DpTekBIMConsulting" target="_blank" rel="noopener noreferrer">
              <FaFacebookF />
            </a>
            <a
              href="https://wa.me/61469743576"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BsWhatsapp />
            </a>
            <a href="https://www.linkedin.com/in/alam-dptek/" target="_blank" rel="noopener noreferrer">
              <FaLinkedinIn />
            </a>
          </div>
        </div>
        <form className="Contact-part2" onSubmit={submitHandler}>
          <label htmlFor="">Your Name</label>
          <input
            type="text"
            name="name"
            id=""
            onChange={changeHandler}
            required
          />
          <label htmlFor="">Your Email</label>
          <input
            type="email"
            name="email"
            id=""
            onChange={changeHandler}
            required
          />
          <label htmlFor="">Your Contact Number</label>
          <input type="number" name="phone" onChange={changeHandler} required />
          <label htmlFor="">Your Enquiry</label>
          <textarea name="message" onChange={changeHandler} rows="5"></textarea>
          <button type="submit">Submit</button>
          <h5>{successResult ? <SuccessResult /> : null}</h5>
          <h5>{failureResult ? <FailureResult /> : null}</h5>
        </form>
      </div>

      <p className="copyright-text">&copy; 2023 by DpTek Services.</p>
    </div>
  );
};

export default Contact;
