import React from "react";
import "./projects.css";
import { Link } from "react-router-dom";


import data from "./data";
import experData from './experienceproject';

const Projects = () => {
  return (
    <div className="projectscontainerPage">
      <h1 className="projects-tag">Recent Projects</h1>
      <p className="projects-type">
        Type : Sturctural precast panel & Steel Roof
      </p>

      <div className="projectscontainer-1">
        {data.map((project) => (
          <div
            className="projects-img-container-1"
            data-aos="fade-up"
            key={project._id}
            data-aos-duration="2000"
            data-aos-anchor-placement="top-bottom"
          >
            <Link
              to={`/projects/${project.link}`}
              onClick={() => {
                window.scroll(0, 0);
              }}
            >
              <img
                className="projects-img1"
                src={require(`../../Images/projects-img/${project.image}`)}
                alt=""
              />
              <div className="overlay overlay_1">
                <h3>{project.title}</h3>
              </div>
            </Link>
          </div>
        ))}
      </div>


      
      <h1 className="projects-tag projects-other-tag">
        Other types of projects we have <br /> experience on:
      </h1>
      {/* <div className="projectscontainer-1">
        <div
          className="projects-img-container-1"
          data-aos="fade-up"
          data-aos-duration="2000"
          data-aos-anchor-placement="top-bottom"
        >
          <img className="projects-img1" src={expimg1} alt="" />
          <div className="overlay overlay_1">
            <p className="exp-project-text">
              Type: Structural steel and precast panel cladding
            </p>
          </div>
        </div>
        <div
          className="projects-img-container-1"
          data-aos="fade-up"
          data-aos-duration="2000"
          data-aos-anchor-placement="top-bottom"
        >
          <img className="projects-img1" src={expimg2} alt="" />
          <div className="overlay overlay_1">
            <p className="exp-project-text">
              Type: Structural steel and precast panel cladding
            </p>
          </div>
        </div>
        <div
          className="projects-img-container-1"
          data-aos="fade-up"
          data-aos-duration="2000"
          data-aos-anchor-placement="top-bottom"
        >
          <img className="projects-img1" src={expimg3} alt="" />
          <div className="overlay overlay_1">
            <p className="exp-project-text">
              Type: Structural steel and precast panel cladding
            </p>
          </div>
        </div>
        <div
          className="projects-img-container-1"
          data-aos="fade-up"
          data-aos-duration="2000"
          data-aos-anchor-placement="top-bottom"
        >
          <img className="projects-img1" src={expimg4} alt="" />
          <div className="overlay overlay_1">
            <p className="exp-project-text">
              Type: Structural steel and sheet cladding
            </p>
          </div>
        </div>
      </div> */}
       <div className="projectscontainer-2">
        {experData.map((experienceproject) => (
         <div
         className="projects-img-container-1"
         data-aos="fade-up"
         data-aos-duration="2000"
         data-aos-anchor-placement="top-bottom"
       >
       <Link
              to={`/experienceprojects/${experienceproject.link}`}
              onClick={() => {
                window.scroll(0, 0);
              }}
            >
         <img className="projects-img1" src={require(`../../Images/experience-projects/${experienceproject.image}`)} alt="" />
         <div className="overlay overlay_1">
           <p className="exp-project-text">
             Type: {experienceproject.title}
           </p>
         </div>
         </Link>
       </div>
        ))}
      </div>
    </div>
  );
};

export default Projects;
