import React from "react";
import banner from "../../../Images/Linkedin Cover update.jpg";
import "./Banner.css";

const Banner = () => {
  return (
    <div className="container">
      <img src={banner} alt="" />
    </div>
  );
};

export default Banner;
