import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import About from './Pages/About/About';
import Home from './Pages/Home/Home';
import ScrollToTop from "react-scroll-to-top";
import Services from './Pages/Services/Services';
import Projects from './Pages/Projects/Projects';
import ProjectsDetail from './Pages/ProjectsDetail/ProjectsDetail';
import MainLayout from './Pages/MainLayout/MainLayout';
import Error from './Pages/Error/Error';
import ExperienceProjectsDetail from './Pages/Projects/ExperienceProjectsDetail/ExperienceProjectsDetail';
// import Testimonial from './Pages/Testimonial/Testimonial';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
            <Routes>
              <Route  exact path='/' element={<MainLayout/>}>
              <Route  index  element={<Home/>} />
              <Route  exact path="/about"  element={<About/>} />
              <Route  exact path="/services"  element={<Services/>} />
              <Route exact  path="/projects"  element={<Projects/>}>
              </Route>
              <Route exact path="projects/:projectId"  element={<ProjectsDetail/>} />
              <Route exact path="experienceprojects/:experienceprojectId"  element={<ExperienceProjectsDetail/>} />
              {/* <Route path="testimonial"  element={<Testimonial/>} /> */}
            </Route>
            <Route  path="*"  element={<Error/>} />
            </Routes>
        </BrowserRouter>
        <ScrollToTop  smooth />
    </div>
  );
}

export default App;