import React from 'react';
import HomePageAboutPart from './HomePageAboutPart/HomePageAboutPart';
import HomePageProjects from './HomePageProjects/HomePageProjects';
import Banner from './Banner/Banner';
import HomePageServices from './HomePageServices/HomePageServices';

const Home = () => {
    return (
        <div>
            <Banner/>
            <HomePageAboutPart/> 
            <HomePageServices/>   
            <HomePageProjects/>
        </div>
    );
};

export default Home;