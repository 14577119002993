import React from "react";
import logo from "../../Images/logo2.jpeg";
import "./Services.css";
import { Link } from "react-router-dom";
const Services = () => {
  return (
    <div id="services">
      <div className="row">
        <h1 className="services-tag">Our Services</h1>
        <div>
          <div className="featuredPropBox">
            <ul>
              <li data-aos="zoom-in-left" data-aos-duration="2000">
                <div className="fplogo-service">
                  <img src={logo} alt="fp1" />
                  <h2>
                    Structural steel <br />
                    Detailing
                  </h2>
                </div>
                <div className="service-detail-pic service-detail-pic-1"></div>
                <div className="fptext">
                  <div>
                    <p>
                      Our clients rely on our expertise in steel detailing for
                      industrial buildings, which has become a daily routine for
                      us.
                    </p>
                    <p>
                      To ensure accuracy, we have created a comprehensive set of
                      detailing, review, and quality assurance checklists for
                      double-checking our work.
                    </p>
                    <p>◉ Installation drawings</p>
                    <p>◉ Assembly shop drawings</p>
                    <p>◉ Individual Part drawings</p>
                    <p>◉ NC/DXF files</p>
                    <p>◉ Materials, assemblies, parts & bolts reports</p>
                    <p>◉ 3D models in .ifc, DWG</p>
                    <p>◉ POWERFAB reports</p>
                    <p>◉ Strumis reports</p>
                    <p>◉ Purlin & Girt lotting</p>

                    <Link
                      to="/projects"
                      onClick={() => {
                        window.scroll(0, 0);
                      }}
                    >
                      <button>Learn more</button>
                    </Link>
                  </div>
                </div>
              </li>
              <li data-aos="zoom-in-down" data-aos-duration="3000">
                <div className="fplogo-service">
                  <img src={logo} alt="fp2" />
                  <h2>
                    Precast Concrete
                    <br /> Detailing{" "}
                  </h2>
                </div>
                <div className="service-detail-pic service-detail-pic-2"></div>
                <div className="fptext">
                  <div>
                    <p>
                      Throughout our tenure, we have gained extensive industry
                      knowledge by participating in numerous large-scale precast
                      projects both in Australia and overseas.
                    </p>
                    <p>
                      Our utilization of BIM tools such as Tekla Structures and
                      Revit, collaboration tool Trimble Connect, and Tekla API
                      and Parametric design tools ensures error-free output.
                    </p>
                    <p> ◉ Panel layout plan and Elevations</p>
                    <p> ◉ Dowel layout plan</p>
                    <p> ◉ Pier layout plan</p>
                    <p> ◉ Propping layout plan</p>
                    <p> ◉ Panel shop drawing</p>
                    <p> ◉ Panel report</p>
                    <p> ◉ Panel cast-in reports</p>

                    <Link
                      to="/projects"
                      onClick={() => {
                        window.scroll(0, 0);
                      }}
                    >
                      <button>Learn more</button>
                    </Link>
                  </div>
                </div>
              </li>
              <li data-aos="zoom-in-right" data-aos-duration="2000">
                <div className="fplogo-service">
                  <img src={logo} alt="fp3" />
                  <h2>Tekla Automation</h2>
                </div>
                <div className="service-detail-pic service-detail-pic-3"></div>
                <div className="fptext">
                  <div>
                    <p>
                      After spending a considerable amount of time in the
                      industry, we noticed the limitations of traditional
                      system.
                    </p>
                    <p>
                      As a result, we worked hard to create a set of
                      comprehensive tools to enable us to model all major trades
                      in a single model.
                    </p>
                    <p> ◉ Tekla API tool</p>
                    <p> ◉ Grasshopper tool</p>
                    <p> ◉ Tekla Custom component</p>
                    <p> ◉ Custom report</p>

                    <Link
                      to="/projects"
                      onClick={() => {
                        window.scroll(0, 0);
                      }}
                    >
                      <button>Learn more</button>
                    </Link>
                  </div>
                </div>
              </li>

              <li data-aos="zoom-in-up" data-aos-duration="3000">
                <div className="fplogo-service">
                  <img src={logo} alt="fp1" />
                  <h2>Estimation Modeling</h2>
                </div>
                <div className="service-detail-pic service-detail-pic-4"></div>
                <div className="fptext">
                  <div>
                    <p>
                      Our team provides a fast and accurate estimation service
                      by utilizing various API tools and parametric software
                      such as Rhino and Grasshopper to create detailed schematic
                      models.
                    </p>
                    <p>◉ Material reports. Software used:</p>
                    <p>◉ Tekla Structures</p>
                    <p>◉ Trimble Connect</p>
                    <p>◉ Revit</p>
                    <p>◉ Rhino and Grasshopper</p>

                    <Link
                      to="/projects"
                      onClick={() => {
                        window.scroll(0, 0);
                      }}
                    >
                      <button>Learn more</button>
                    </Link>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="ServicesPageAboutPart">
        <div
          className="Services-img"
          data-aos="zoom-in-right"
          data-aos-duration="3000"
        ></div>
        {/* <img src={service1}   data-aos-duration="3000" className="img2" alt="" /> */}
        <div className="ServicesPageAboutParttext">
          <h1>
            DpTek has experience and expertise in a wide range of sectors, with
            a particular focus on commercial and industrial construction.
          </h1>
          <p>
            The following industries benefit from DpTek's extensive experience:
          </p>
          <li>
            <p
              className="line-div-service"
              data-aos="fade-right"
              data-aos-duration="1000"
            ></p>
            <p>Factory Units & Warehouses.</p>
          </li>
          <li>
            <p
              className="line-div-service"
              data-aos="fade-right"
              data-aos-duration="2000"
            ></p>
            <p>Commercial Offices.</p>
          </li>
          <li>
            <p
              className="line-div-service"
              data-aos="fade-right"
              data-aos-duration="3000"
            ></p>
            <p>Showroom & Retail Developments.</p>
          </li>
          <li>
            <p
              className="line-div-service"
              data-aos="fade-right"
              data-aos-duration="3000"
            ></p>
            <p>Restaurant, Service Station.</p>
          </li>
          <li>
            <p
              className="line-div-service"
              data-aos="fade-right"
              data-aos-duration="3000"
            ></p>
            <p>Educational Facilities.</p>
          </li>
          <li>
            <p
              className="line-div-service"
              data-aos="fade-right"
              data-aos-duration="3000"
            ></p>
            <p>Hotel & Multi Residential Developments.</p>
          </li>
          <li>
            <p
              className="line-div-service"
              data-aos="fade-right"
              data-aos-duration="3000"
            ></p>
            <p>Cold Storage & Food Processing Facilities.</p>
          </li>
          <li>
            <p
              className="line-div-service"
              data-aos="fade-right"
              data-aos-duration="3000"
            ></p>
            <p>Agricultural Development.</p>
          </li>
          <Link to="/projects">
            <button data-aos="fade-right" data-aos-duration="2000">
              see Projects
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Services;
