import React from 'react';
import Slider from "react-slick";
import {
  MdOutlineKeyboardDoubleArrowRight,
  MdOutlineKeyboardDoubleArrowLeft,
} from "react-icons/md";
import { Link, useNavigate, useParams } from "react-router-dom";
import experienceproject from "../experienceproject.json";
// import "../../Projects/Projects.css";
function Arrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}
var settings = {
  dots: true,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 3000,
  pauseOnHover: true,
  slidesToShow: 2,
  nextArrow: <Arrow />,
  prevArrow: <Arrow />,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    // {
    //   breakpoint: 480,
    //   settings: {
    //     slidesToShow: 1,
    //     slidesToScroll: 1
    //   }
    // }
  ],
};
const ExperienceProjectsDetail = () => {

    const { experienceprojectId } = useParams();
  const navigate = useNavigate();
  const projectName = experienceproject.find((project) => {
    return project.link===experienceprojectId ;
  });
  let changeId = parseInt(projectName?._id);

  function prev() {
    changeId = (parseInt(projectName?._id) - 1).toString();
    experienceproject.find((project) => {
      if (project._id === changeId) {
        if (parseInt(changeId) > 0) {
          document.getElementById("next").disabled = false;
          setTimeout(() => {
            navigate(`/experienceprojects/${project.link}`);
          }, 0.0000001);
        }
        if (parseInt(changeId) === 1) {
          document.getElementById("prev").disabled = true;
        }
      }
    });
  }
  function next() {
    changeId = (parseInt(projectName?._id) + 1).toString();
    experienceproject.find((project) => {
      if (project._id === changeId) {
        if (parseInt(changeId) <= experienceproject.length) {
          document.getElementById("prev").disabled = false;
          setTimeout(() => {
            navigate(`/experienceprojects/${project.link}`);
          }, 0.0000001);
        }
        if (parseInt(changeId) ===experienceproject.length) {
          document.getElementById("next").disabled = true;
        }
      }
    });
  }

  const details = experienceproject.find((project) => {
    return project._id === projectName?._id;
  });

  if (!details) {
    setTimeout(() => {
      navigate("/*");
    }, 0.0000001);
  }

//   const toggle = (index) => {
//     var trailer = document.querySelector(".trailer");
//     var video = document.querySelector(".trailer video");
//     trailer.classList.add("active");
//     var count = index;
//     if (details?.video) {
//       document.querySelector(
//         ".trailer video"
//       ).src = require(`../../Images/video/${details.video[count]}`);
//       video.load();
//       video.play();
//     }
//     if (details?.video.length > 1) {
//       video.addEventListener("ended", myHandler, false);

//       function myHandler(e) {
//         if (!e) {
//           e = window.event;
//         }
//         count++;
//         if (details?.video[count]) {
//           document.querySelector(
//             ".trailer video"
//           ).src = require(`../../Images/video/${details.video[count]}`);
//           video.load();
//           video.play();
//         }
//       }
//     }
//   };
//   function close() {
//     var trailer = document.querySelector(".trailer");
//     var video = document.querySelector(".trailer video");
//     trailer.classList.remove("active");
//     video.pause();
//     video.currentTime = 0;
//   }
 
    return (
        <>
      {details && (
        <div className="project-detail-container">
          <div className="Projects-Detailing-text">
            <div>
              <div className=" project-title">
                <h2>{details?.title}.</h2>
                
              </div>
              <div className="Scope-title">
             <h4>Scope of Work</h4>
             {details?.work && details?.work.map((wor) => <li key={wor}>{wor}</li>)}
                <div className="btn-div">
                      <button id="prev" onClick={prev} className="details-link">
                        <MdOutlineKeyboardDoubleArrowLeft className="md-icon" />
                        prev proj.
                      </button>
                      <Link to="/projects">
                        <button id="back-to-projects-btn">Back to projects</button>
                      </Link>
                      <button id="next" onClick={next} className="details-link">
                        next proj.
                        <MdOutlineKeyboardDoubleArrowRight className="md-icon" />
                      </button>
                </div>
              </div>
            </div>
            <div className="delivery-div">
              <h2>Deliverables</h2>

              <div className="delivery-texts">
                <div className="delivery-texts-part-1">
                 { details?.delivery.map(
                  (item, index) => 
                    index < 5 && ( 
                      <li  
                        key={item.id} 
                     
                      >{item}</li>
                    )
                    )
            }
                </div>

                <div>
                { details?.delivery.map(
                  (item, index) => 
                    index >=5 && ( 
                      <li 
                        key={item.id} 
                     
                      >{item}</li>
                    )
                    )
            }
             
                </div>
              </div>
            </div>
          </div>
          <div className="project-container-carousel">
            <Slider {...settings}>
              {details?.screenShot &&
                details?.screenShot.map((projectimg) => (
                  <div className="item">
                    <img
                      src={require(`../../../Images/experience-projects/${projectimg}`)}
                      alt=""
                    />
                  </div>
                ))}
            </Slider>
          </div>
      

        
        </div>
      )}
    </>
    );
};

export default ExperienceProjectsDetail;
