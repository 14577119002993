import React from "react";
import "./Error.css";
import { Link } from "react-router-dom";
const Error = () => {
  return (
    <div className="error-container">
      <div className="section">
        <h1 className="error">404</h1>
        <div className="page">
          Ooops!!! The page you are looking for is not found.
        </div>
        <Link className="back-home" to="/">
          Back to home
        </Link>
        <p className="error-copyright-text">&copy; 2023 by DpTek Services.</p>
      </div>
    </div>
  );
};

export default Error;
