import React from "react";
import "./HomePageAboutPart.css";
import aboutimg2 from "../../../Images/Screenshot 2023-05-24 000727.png";
import { Link } from "react-router-dom";
const HomePageAboutPart = () => {
  return (
    <div>
      <div className="HomePageAboutPart">
        <div>
          <h1>Introduction</h1>
          <p>
            Our clients trust us as dependable detailer because we hold
            ourselves accountable for the quality of our services.
          </p>
          <p className="Home-page-About-highlight-text">
            Our integrated modeling system seamlessly combines structural steel,
            precast panels, and cast-in-situ foundations into a single,
            collaborative model. This eliminates errors and ensures success from
            pre-construction to completion.
          </p>
          <p>
            We take pride in our clients' appreciation of our ability to
            simplify their collaborative tasks. With my assistance, they can
            easily review all aspects in one model.
          </p>
          <p className="Home-page-About-highlight-text">
            So, trust us to make your next project a flawless success with our
            innovative solution.
          </p>

          <Link
            to="/about"
            onClick={() => {
              window.scroll(0, 0);
            }}
          >
            <button>About us</button>
          </Link>
        </div>

        <img
          src={aboutimg2}
          data-aos="zoom-in-left"
          data-aos-duration="3000"
          className="img1"
          alt=""
        />
      </div>
    </div>
  );
};

export default HomePageAboutPart;
