import React from "react";
import "./HomePageProjects.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import projects from "../../Projects/data";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
const HomePageProjects = () => {
  const options = {
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      900: {
        items: 3,
      },
      1000: {
        items: 3,
      },
    },
  };
  return (
    <div className="HomePageProjects-part">
      <div className="HomePageProjects">
        <h2>FEATURED PROJECTS</h2>

        <OwlCarousel
          items={3}
          className="owl-theme"
          {...options}
          loop
          autoplayHoverPause={true}
          autoplayTimeout={2000}
          autoplay={true}
          nav
          margin={12}
        >
          {projects.map((project) => (
            <div className="home-page-projects-img-div" key={project._id}>
              <Link
                to={`projects/${project._id}`}
                onClick={() => {
                  window.scroll(0, 0);
                }}
              >
                <img
                  src={require(`../../../Images/projects-img/${project.image}`)}
                  className="home-page-projects-img"
                  alt=""
                />

                <div className="project-Name">
                  <h3>{project.title}</h3>
                </div>
              </Link>
            </div>
          ))}
        </OwlCarousel>
        <Link
          to="/projects"
          onClick={() => {
            window.scroll(0, 0);
          }}
        >
          <button className="project-view-btn">VIEW ALL PROJECTS</button>{" "}
        </Link>
      </div>
    </div>
  );
};

export default HomePageProjects;
