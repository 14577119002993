import React from "react";
import "./Navbar.css";
import logo from "../../../Images/logo1.jpg";
import { AiFillInstagram } from "react-icons/ai";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import { BsWhatsapp } from "react-icons/bs";
import { Link, NavLink } from "react-router-dom";
const Navbar = () => {
  const hideNavLinks = () => {
    const a = document.getElementById("nav-toogle").checked == false;
  };
  return (
    <div>
      <nav>
        <input id="nav-toggle" type="checkbox" />
        <Link to="/">
          <div className="logo">
            <img src={logo} alt="" />
            <strong>DpTek</strong>
            <p>BIM CONSULTING</p>
          </div>
        </Link>
        <ul className="links">
          <li className="navbar-list">
            <NavLink
              onClick={() => hideNavLinks()}
              className={({ isActive }) => (isActive ? "active" : "inactive")}
              to="/"
            >
              HOME
            </NavLink>
          </li>
          <li className="navbar-list">
            <NavLink
              onClick={() => hideNavLinks()}
              className={({ isActive }) => (isActive ? "active" : "inactive")}
              to="/about"
            >
              ABOUT US
            </NavLink>
          </li>
          <li className="navbar-list">
            <NavLink
              onClick={() => hideNavLinks()}
              className={({ isActive }) => (isActive ? "active" : "inactive")}
              to="/services"
            >
              SERVICES
            </NavLink>
          </li>
          <li className="navbar-list">
            <NavLink
              onClick={() => hideNavLinks()}
              className={({ isActive }) => (isActive ? "active" : "inactive")}
              to="/projects"
            >
              PROJECTS
            </NavLink>
          </li>
          {/* <li className="navbar-list"><NavLink onClick={()=>hideNavLinks()} className={({ isActive }) => (isActive ? "active" : "inactive")} to="/testimonial">TESTIMONIALS</NavLink></li> */}
          {/* <li className="navbar-list"><NavLink onClick={()=>hideNavLinks()} className={({ isActive }) => (isActive ? "active" : "inactive")} to="/#contact">CONTACT</NavLink></li> */}
          <li className="navbar-list contact">
            <a href="#contact">CONTACT</a>
          </li>
          <div className="social-media">
            <a href="https://twitter.com/DptekC99319"  target="_blank" rel="noopener noreferrer">
              <FaTwitter />
            </a>
            <a href="https://www.instagram.com/dptekbc/" target="_blank" rel="noopener noreferrer">
              <AiFillInstagram />
            </a>
            <a href="https://www.facebook.com/DpTekBIMConsulting" target="_blank" rel="noopener noreferrer">
              <FaFacebookF />
            </a>
            <a href="https://wa.me/61469743576" target="_blank" rel="noopener noreferrer">
              <BsWhatsapp />
            </a>
            <a href="https://www.linkedin.com/in/alam-dptek/" target="_blank" rel="noopener noreferrer">
              <FaLinkedinIn />
            </a>
          </div>
        </ul>

        <label for="nav-toggle" className="icon-burger">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </label>
      </nav>
    </div>
  );
};

export default Navbar;
