import React from "react";
import "./About.css";
const About = () => {
  return (
    <div className="AboutPage">
      <div className="AboutPagePart-2">
        <div
          className="AboutPageImage-2"
          data-aos="zoom-in-down"
          data-aos-duration="3000"
        ></div>
        <div className="AboutPageTextPart-2">
          <h1>Introduction</h1>
          <p>
            Our clients trust us as dependable detailers because we hold
            ourselves accountable for the quality of our services.
          </p>
          <p className="About-page-highlight-text">
            Our integrated modeling system seamlessly combines structural steel,
            precast panels, and cast-in-situ foundations into a single,
            collaborative model. This eliminates errors and ensures success from
            pre-construction to completion.
          </p>
          <p>
            We take pride in our clients' appreciation of our ability to
            simplify their collaborative tasks. With my assistance, they can
            easily review all aspects in one model.
          </p>
          <p className="About-page-highlight-text">
            So, trust us to make your next project a flawless success with our
            innovative solution.
          </p>
          <h3>Alam Md Didarul</h3>
          <p>Director and Consultant, DpTek.</p>
        </div>
      </div>
      <div className="AboutPagePart-1">
        <div className="AboutPageTextPart-1">
          <h1>
            Our valued clients regard us as a reliable detailer as we ensure
            complete accountability for our services. With our integrated
            modeling system, we can seamlessly combine structural steel, precast
            panels, and cast-in-situ foundations into one collaborative model.
            Say goodbye to errors and hello to success from pre-construction to
            finish. Make your next project a flawless one with our innovative
            solution.
          </h1>
          <p className="About-page-highlight-text">
            After spending a considerable amount of time in the industry, we
            noticed the limitations of traditional systems. As a result, we
            worked hard to create a set of comprehensive tools and manpower that
            would enable us to model all major trades in a single model.
          </p>
          <p>
            As a service provider based in Australia, I take on the role of both
            local coordinator and lead detailer. I strive to lead my team toward
            excellence by ensuring that all work is completed with attention to
            every detail. With our comprehensive approach, we can visualize
            every corner of the project in detail in ONE LOCAL WORKING MODEL.
          </p>
          <p>
            We have extensive experience in providing detailing services across
            various sectors, including Commercial and Industrial. They
            specialize in Concrete Tilt-Up construction and have specific
            expertise in detailing Offices, Large Factory Units, Warehouses,
            Food Processing, and Storage Facilities.
          </p>
          <div>
            <li>
              <div
                className="about-page-line-div"
                data-aos="fade-right"
                data-aos-duration="1000"
              ></div>
              <p>Proudly Australian family owned and operated.</p>
            </li>
            <li>
              <div
                className="about-page-line-div"
                data-aos="fade-right"
                data-aos-duration="2000"
              ></div>
              <p>
                Experience in integrated steel and precast detailing using Tekla
                and Revit.
              </p>
            </li>
            <li>
              <div
                className="about-page-line-div"
                data-aos="fade-right"
                data-aos-duration="3000"
              ></div>
              <p>Model coordination through Trimble Connect.</p>
            </li>
          </div>
        </div>

        <div
          className="AboutPageImage-1"
          data-aos="zoom-in-up"
          data-aos-duration="3000"
        ></div>
      </div>
    </div>
  );
};

export default About;
