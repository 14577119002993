import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../Home/Navbar/Navbar";
import Contact from "../Home/Contact/Contact";

const MainLayout = () => {
  return (
    <>
      <Navbar />
      <Outlet />
      <Contact />
    </>
  );
};

export default MainLayout;
